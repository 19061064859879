import React, { Component } from "react";
import img_1 from "./img/portfolio/fullsize/1.jpg";
import thumb_1 from "./img/portfolio/thumbnails/1.jpg";
import img_2 from "./img/portfolio/fullsize/2.jpg";
import thumb_2 from "./img/portfolio/thumbnails/2.jpg";
import img_3 from "./img/portfolio/fullsize/3.jpg";
import thumb_3 from "./img/portfolio/thumbnails/3.jpg";
import img_4 from "./img/portfolio/fullsize/4.jpg";
import thumb_4 from "./img/portfolio/thumbnails/4.jpg";
import img_5 from "./img/portfolio/fullsize/5.jpg";
import thumb_5 from "./img/portfolio/thumbnails/5.jpg";
import img_6 from "./img/portfolio/fullsize/6.jpg";
import thumb_6 from "./img/portfolio/thumbnails/6.jpg";

export default class Portfolio extends Component {
  render() {
    return (
      <section className="p-0" id="portfolio">
        <div className="container-fluid p-0">
          <div className="row no-gutters popup-gallery">
            <div className="col-lg-4 col-sm-6">
              <a className="portfolio-box" href={img_1}>
                <img className="img-fluid" src={thumb_1} alt="" />
                <div className="portfolio-box-caption">
                  <div className="portfolio-box-caption-content">
                    <div className="project-category text-faded">Category</div>
                    <div className="project-name">Project Name</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a className="portfolio-box" href={img_2}>
                <img className="img-fluid" src={thumb_2} alt="" />
                <div className="portfolio-box-caption">
                  <div className="portfolio-box-caption-content">
                    <div className="project-category text-faded">Category</div>
                    <div className="project-name">Project Name</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a className="portfolio-box" href={img_3}>
                <img className="img-fluid" src={thumb_3} alt="" />
                <div className="portfolio-box-caption">
                  <div className="portfolio-box-caption-content">
                    <div className="project-category text-faded">Category</div>
                    <div className="project-name">Project Name</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a className="portfolio-box" href={img_4}>
                <img className="img-fluid" src={thumb_4} alt="" />
                <div className="portfolio-box-caption">
                  <div className="portfolio-box-caption-content">
                    <div className="project-category text-faded">Category</div>
                    <div className="project-name">Project Name</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a className="portfolio-box" href={img_5}>
                <img className="img-fluid" src={thumb_5} alt="" />
                <div className="portfolio-box-caption">
                  <div className="portfolio-box-caption-content">
                    <div className="project-category text-faded">Category</div>
                    <div className="project-name">Project Name</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <a className="portfolio-box" href={img_6}>
                <img className="img-fluid" src={thumb_6} alt="" />
                <div className="portfolio-box-caption">
                  <div className="portfolio-box-caption-content">
                    <div className="project-category text-faded">Category</div>
                    <div className="project-name">Project Name</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
